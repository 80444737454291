var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "id-modal-quote-on-booking-search",
      "title": _vm.$t('flight.copyPrice'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "p-1",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": function show($event) {
        return _vm.onShowModalQuote();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-danger",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$root.$emit('bv::hide::modal', 'id-modal-quote-on-booking-search');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.cancel')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient width-150 mt-lg-25",
          attrs: {
            "pill": "",
            "disabled": _vm.disabledCopy
          },
          on: {
            "click": function click($event) {
              return _vm.copySmsContentToClipboard();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.copy')) + " ")]), _c('b-button', {
          staticClass: "width-150 mt-lg-25",
          attrs: {
            "variant": "outline-success",
            "pill": "",
            "disabled": _vm.disabledExport
          },
          on: {
            "click": function click($event) {
              return _vm.$bvModal.show('split-amount-modal');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.export')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "small": ""
    },
    model: {
      value: _vm.segmentIndex,
      callback: function callback($$v) {
        _vm.segmentIndex = $$v;
      },
      expression: "segmentIndex"
    }
  }, [_vm._l(_vm.titleArr, function (titleObj, sIndex) {
    return _c('b-tab', {
      key: "segment".concat(sIndex),
      attrs: {
        "title": "".concat(titleObj.startPoint, "-").concat(titleObj.endPoint)
      }
    });
  }), _vm.isCombinationProps ? _c('b-tab', {
    attrs: {
      "title": "GKH: ".concat(_vm.titleArr.map(function (i) {
        return "".concat(i.startPoint, "-").concat(i.endPoint);
      }))
    }
  }) : _vm._e()], 2), _vm.smsContentsArr && _vm.smsContentsArr.length ? _c('b-tabs', {
    attrs: {
      "lazy": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": _vm.$t('reservation.sms.vnWithAccent')
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px"
    },
    attrs: {
      "rows": "16"
    },
    model: {
      value: _vm.textVnWithAccent,
      callback: function callback($$v) {
        _vm.textVnWithAccent = $$v;
      },
      expression: "textVnWithAccent"
    }
  })], 1), _c('b-tab', {
    attrs: {
      "disabled": _vm.isDisabledShortenPriceReport,
      "title": _vm.$t('reservation.sms.vnWithAccentShorten')
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px"
    },
    attrs: {
      "rows": "16"
    },
    model: {
      value: _vm.textVnWithAccentShorten,
      callback: function callback($$v) {
        _vm.textVnWithAccentShorten = $$v;
      },
      expression: "textVnWithAccentShorten"
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": _vm.$t('reservation.sms.vnNoAccent')
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px"
    },
    attrs: {
      "rows": "16"
    },
    model: {
      value: _vm.textVnNoAccent,
      callback: function callback($$v) {
        _vm.textVnNoAccent = $$v;
      },
      expression: "textVnNoAccent"
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": _vm.$t('reservation.sms.en')
    }
  }, [_c('b-form-textarea', {
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px"
    },
    attrs: {
      "rows": "16"
    },
    model: {
      value: _vm.textEn,
      callback: function callback($$v) {
        _vm.textEn = $$v;
      },
      expression: "textEn"
    }
  })], 1)], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "split-amount-modal",
      "size": "sm",
      "centered": true,
      "no-close-on-backdrop": true,
      "no-close-on-esc": true,
      "hide-footer": false,
      "title": _vm.$t('flight.splitAmount')
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 d-flex-center gap-2"
        }, [_c('b-button', {
          staticClass: "width-150 mt-lg-25",
          attrs: {
            "variant": "outline-success",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.exportItinerary('img');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.exportIMG')) + " ")]), _c('b-button', {
          staticClass: " width-150 mt-lg-25",
          attrs: {
            "variant": "outline-dark",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.exportItinerary('pdf');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.exportPDF')) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "splitAmount"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('flight.splitAmount')) + " "), true ? _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "id": "splitAmount",
      "options": [15, 20, 25, 30],
      "getOptionLabel": function getOptionLabel(data) {
        return data.label;
      },
      "clearable": false,
      "placeholder": _vm.$t('flight.splitAmount')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions|common.noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.splitAmount,
      callback: function callback($$v) {
        _vm.splitAmount = $$v;
      },
      expression: "splitAmount"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }