<template>
  <div>
    <b-modal
      id="id-modal-quote-on-booking-search"
      :title="$t('flight.copyPrice')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="p-1"
      centered
      no-close-on-backdrop
      size="lg"
      @show="onShowModalQuote()"
    >
      <b-tabs
        v-model="segmentIndex"
        pills
        small
      >
        <b-tab
          v-for="(titleObj, sIndex) in titleArr"
          :key="`segment${sIndex}`"
          :title="`${titleObj.startPoint}-${titleObj.endPoint}`"
        />

        <b-tab
          v-if="isCombinationProps"
          :title="`GKH: ${titleArr.map(i => `${i.startPoint}-${i.endPoint}`)}`"
        />
      </b-tabs>

      <b-tabs
        v-if="smsContentsArr && smsContentsArr.length"
        v-model="tabIndex"
        lazy
      >
        <b-tab
          active
          :title="$t('reservation.sms.vnWithAccent')"
        >
          <b-form-textarea
            v-model="textVnWithAccent"
            rows="16"
            style="font-size: 16px; line-height: 24px;"
          />
        </b-tab>

        <b-tab
          :disabled="isDisabledShortenPriceReport"
          :title="$t('reservation.sms.vnWithAccentShorten')"
        >
          <b-form-textarea
            v-model="textVnWithAccentShorten"
            rows="16"
            style="font-size: 16px; line-height: 24px;"
          />
        </b-tab>

        <b-tab :title="$t('reservation.sms.vnNoAccent')">
          <b-form-textarea
            v-model="textVnNoAccent"
            rows="16"
            style="font-size: 16px; line-height: 24px;"
          />
        </b-tab>

        <b-tab :title="$t('reservation.sms.en')">
          <b-form-textarea
            v-model="textEn"
            rows="16"
            style="font-size: 16px; line-height: 24px;"
          />
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          pill
          @click="$root.$emit('bv::hide::modal', 'id-modal-quote-on-booking-search')"
        >
          {{ $t('customer.cancel') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient width-150 mt-lg-25"
          pill
          :disabled="disabledCopy"
          @click="copySmsContentToClipboard()"
        >
          {{ $t('reservation.copy') }}
        </b-button>

        <b-button
          variant="outline-success"
          pill
          class="width-150 mt-lg-25"
          :disabled="disabledExport"
          @click="$bvModal.show('split-amount-modal')"
        >
          {{ $t('flight.export') }}
        </b-button>
      </template>
    </b-modal>

    <!-- ANCHOR modal split-amount-modal. -->
    <b-modal
      id="split-amount-modal"
      size="sm"
      :centered="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="false"
      :title="$t('flight.splitAmount')"
    >
      <!-- ANCHOR select splitAmount -->
      <b-col md="12">
        <b-form-group label-for="splitAmount">
          <template #label>
            {{ $t('flight.splitAmount') }}
            <span
              v-if="true"
              class="text-danger"
            >*</span>
          </template>
          <v-select
            id="splitAmount"
            v-model="splitAmount"
            :options="[15,20,25,30]"
            :getOptionLabel="data => data.label"
            :clearable="false"
            :placeholder="$t('flight.splitAmount')"
          >
            <template #option="data">
              <span>
                {{ data.label }}
              </span>
            </template>
            <template #selected-option="data">
              <span>
                {{ data.label }}
              </span>
            </template>
            <template #no-options>
              {{ $t('noOptions|common.noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <template #modal-footer>
        <div class="w-100 d-flex-center gap-2">
          <b-button
            variant="outline-success"
            pill
            class="width-150 mt-lg-25"
            @click="exportItinerary('img')"
          >
            {{ $t('flight.exportIMG') }}
          </b-button>

          <b-button
            variant="outline-dark"
            pill
            class=" width-150 mt-lg-25"
            @click="exportItinerary('pdf')"
          >
            {{ $t('flight.exportPDF') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  computed,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'
import {
  BButton,
  BCol,
  BFormGroup,
  BFormTextarea,
  BModal,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import jsPDF from 'jspdf'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import upperFirst from 'lodash/upperFirst'
import html2canvas from 'html2canvas-pro'
import chunk from 'lodash/chunk'

import { BUSINESS_FILTER_REGEX, BUSINESS_SKYBOSS_FILTER_REGEX, SKYBOSS_FILTER_REGEX } from '@/constants/flight'
import env from '@/libs/env'
import store from '@/store'
import {
  getBaggagesByBookingClass,
  getHandBaggage,
} from '@/views/apps/configs/ticket/useSegmentBaggage'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'
import {
  convertISODateTime,
  formatCurrency,
  getLongWeekdayFromIso,
  resolveAirlineFlightNumber,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  name: 'ModalQuote',

  components: {
    BModal,
    BTabs,
    BTab,
    BFormTextarea,
    BButton,
    BCol,
    BFormGroup,
    vSelect: () => import('vue-select'),
  },

  props: {
    segmentTabIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    titleArr: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedTrips: {
      type: Array,
      default: () => [],
    },
    combinationSelectedTrip: {
      type: Array,
      default: () => [],
    },
    combinationTripList: {
      type: Array,
      default: () => [],
    },
    tripsArr: {
      type: Array,
      required: true,
      default: () => [],
    },
    isRenderTripsArr: {
      type: Array,
      required: true,
      default: () => [],
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { getBaggageInSearchBooking, FLIGHT_APP_STORE_MODULE_NAME } = useBookingHandle()
    const segmentIndex = ref(props.segmentTabIndex)

    const selectTripsProps = toRefs(props).selectedTrips
    const isCombinationProps = toRefs(props).isCombination
    const combinationTripList = toRefs(props).combinationTripList
    const combinationSelectedTripProps = toRefs(props).combinationSelectedTrip
    const isCombinationSelected = computed(() => !isEmpty(combinationSelectedTripProps.value))
    const selectedTrips = computed(() => isCombinationSelected.value ? combinationSelectedTripProps.value : selectTripsProps.value)
    const tabIndex = ref(0)
    const segmentsCount = computed(() => (props.titleArr.length + (isCombinationProps.value ? 1 : 0)))
    // const employeeConfig = computed(() => store.getters['userStore/getEmployeeConfig'])

    const defaultSmsContentsArr = []
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < segmentsCount.value; index++) {
      defaultSmsContentsArr.push(['', '', ''])
    }
    const smsContentsArr = ref(defaultSmsContentsArr)

    const disabledCopy = ref(false)

    const { copy } = useClipboard()

    function copySmsContentToClipboard() {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(smsContentsArr.value[segmentIndex.value][tabIndex.value])
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(error => {
          this.$root.toastError('reservation.sms.copyFail')
          console.error(this.$t('reservation.sms.copyFail'), error)
        })
    }

    const minPrice = computed(() => store.getters['app-flight-v2/getFilterByPrices']?.[0] || 0)

    function getAirportByAirportCode(airportCode) {
      return store.getters['app-flight-v2/getAirportByAirportCode'](airportCode)
    }

    function getAirlineNameByCode(code) {
      return store.getters['globalConfig/getAirlineNameByCode'](code)
    }

    const getCustomFeeServiceDataToModify = computed(() => store.getters['app-flight-v2/getCustomFeeServiceDataToModify'])
    const getShowPriceAfterDiscount = computed(() => store.getters['app-flight-v2/getShowPriceAfterDiscount'])
    const getShowPrice = computed(() => store.getters['app-flight-v2/getShowPrice'])
    const getFilterByFareTypeBusiness = computed(() => store.getters['app-flight-v2/getFilterByFareTypeBusiness'])
    const getFilterByFareTypeSkyboss = computed(() => store.getters['app-flight-v2/getFilterByFareTypeSkyboss'])

    function totalCustomServiceFee(trip) {
      if (!trip) return ''
      const { fareOptions, source, domestic } = trip
      const getFeeServiceInSelectedProfile = computed(() => store.getters['app-flight-v2/getFeeServiceInSelectedProfile'](domestic, source))

      const modifiedServiceFee = {
        adultAmount: null,
        childAmount: null,
        infantAmount: null,
      }
      // Nếu có custom pdv
      if (getCustomFeeServiceDataToModify.value?.adultAmount !== null || getCustomFeeServiceDataToModify.value?.childAmount !== null || getCustomFeeServiceDataToModify.value?.infantAmount !== null) {
        modifiedServiceFee.adultAmount = getCustomFeeServiceDataToModify.value?.adultAmount * fareOptions?.amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getCustomFeeServiceDataToModify.value?.childAmount * fareOptions?.amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getCustomFeeServiceDataToModify.value?.infantAmount * fareOptions?.amountSegmentToMultipleFeeService
      } else if (getFeeServiceInSelectedProfile.value) {
      // nếu chọn profile pdv
        const isSegmentFeeType = getFeeServiceInSelectedProfile.value?.feeType === 'FLIGHT_SEGMENT'
        const amountSegmentToMultipleFeeService = isSegmentFeeType ? trip?.segments?.length : 1
        modifiedServiceFee.adultAmount = getFeeServiceInSelectedProfile.value?.adultAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getFeeServiceInSelectedProfile.value?.childAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getFeeServiceInSelectedProfile.value?.infantAmount * amountSegmentToMultipleFeeService
      }

      const customServiceFeeAdult = modifiedServiceFee.adultAmount !== null ? modifiedServiceFee.adultAmount - fareOptions?.serviceFeeAdult : 0
      const customServiceFeeChild = modifiedServiceFee.childAmount !== null ? modifiedServiceFee.childAmount - fareOptions?.serviceFeeChild : 0
      const customServiceFeeInfant = modifiedServiceFee.infantAmount !== null ? modifiedServiceFee.infantAmount - fareOptions?.serviceFeeInfant : 0
      return {
        total: (customServiceFeeAdult * fareOptions?.passenger?.adult + customServiceFeeChild * fareOptions?.passenger?.child + customServiceFeeInfant * fareOptions?.passenger?.infant) || 0,
        adult: customServiceFeeAdult,
      }
    }

    function resolveFare(fareOption, trip) {
      return getShowPrice.value === 'TICKET_FARE'
        ? (fareOption?.fareAdult ?? '')
        : getShowPrice.value === 'NET_FARE'
          ? (fareOption?.totalAdult ?? '')
          : getShowPrice.value === 'HIDE_FARE'
            ? ''
            : (fareOption?.totalAdultModified + totalCustomServiceFee({ ...trip, fareOptions: fareOption })?.adult)
              + (!getShowPriceAfterDiscount.value ? (fareOption?.promotionAdult ?? 0) + (fareOption?.discountAdult ?? 0) : 0)
    }

    function getMinFareOptions(trip) {
      const minFare = trip?.fareOptions.find(item => item.afterMultiple.totalAdultModified >= minPrice.value)
      return resolveFare(minFare, trip)
    }

    // callback function t: translation function
    async function getQuoteSmsContent(segmentIndex, t, {
      isVietnamese, isRemoveAccents, shorten = false, combination = false,
    }) {
      // SECTION: Handle selected trip
      const isSelectedTrips = selectedTrips.value.filter(Boolean).length > 0
      if (isSelectedTrips && (selectedTrips.value[segmentIndex] || isCombinationSelected.value)) {
        let sms = ''
        let index = 1
        // eslint-disable-next-line consistent-return
        for (const selectedTrip of selectedTrips.value) {
          if (selectedTrip) {
            // hãng
            const airline = selectedTrip.segments[0].operating || selectedTrip.airline

            // từ - đến
            const departureAirportCode = selectedTrip?.departure?.IATACode
            const arrivalAirportCode = selectedTrip?.arrival?.IATACode
            const departureAirportObj = getAirportByAirportCode(departureAirportCode)
            const arrivalAirportObj = getAirportByAirportCode(arrivalAirportCode)
            let departureAirportName
            let arrivalAirportName
            if (departureAirportObj?.group === 'VIỆT NAM' || departureAirportObj?.group === 'VIETNAM') {
              departureAirportName = `${t(`vnAirports.${departureAirportCode}`)} (${departureAirportCode})`
            } else {
              departureAirportName = isVietnamese
                ? `${departureAirportObj?.cityName}${departureAirportObj?.countryName && departureAirportObj?.countryName !== 'Việt Nam' && departureAirportObj?.countryName !== departureAirportObj?.cityName ? `, ${departureAirportObj?.countryName}` : ''} (${departureAirportCode})`
                : `${departureAirportObj?.cityEnName}${departureAirportObj?.countryEnName && departureAirportObj?.countryEnName !== 'Việt Nam' && departureAirportObj?.countryEnName !== departureAirportObj?.cityEnName ? `, ${departureAirportObj?.countryEnName}` : ''} (${departureAirportCode})`
              // ? `${departureAirportObj?.name}${departureAirportObj?.cityName ? `, ${departureAirportObj?.cityName}` : ''} (${departureAirportCode})`
              // : `${departureAirportObj?.name}${departureAirportObj?.cityEnName ? `, ${departureAirportObj?.cityEnName}` : ''} (${departureAirportCode})`
            }
            if (arrivalAirportObj?.group === 'VIỆT NAM' || arrivalAirportObj?.group === 'VIETNAM') {
              arrivalAirportName = `${t(`vnAirports.${arrivalAirportCode}`)} (${arrivalAirportCode})`
            } else {
              arrivalAirportName = isVietnamese
                ? `${arrivalAirportObj?.cityName ?? arrivalAirportObj?.name}${arrivalAirportObj?.countryName && arrivalAirportObj?.countryName !== 'Việt Nam' && arrivalAirportObj?.countryName !== arrivalAirportObj?.cityName ? `, ${arrivalAirportObj?.countryName}` : ''} (${arrivalAirportCode})`
                : `${arrivalAirportObj?.cityEnName ?? arrivalAirportObj?.name}${arrivalAirportObj?.countryEnName && arrivalAirportObj?.countryEnName !== 'Việt Nam' && arrivalAirportObj?.countryEnName !== arrivalAirportObj?.cityEnName ? `, ${arrivalAirportObj?.countryEnName}` : ''} (${arrivalAirportCode})`
                // ? `${arrivalAirportObj?.name}${arrivalAirportObj?.cityName ? `, ${arrivalAirportObj?.cityName}` : ''} (${arrivalAirportCode})`
                // : `${arrivalAirportObj?.name}${arrivalAirportObj?.cityEnName ? `, ${arrivalAirportObj?.cityEnName}` : ''} (${arrivalAirportCode})`
            }

            // Hành lý: a công bảo tạm ẩn đi
            // eslint-disable-next-line no-unused-vars
            // eslint-disable-next-line no-unused-vars
            // const boardingBaggage = await getBaggageInSearchBooking({
            //   ...selectedTrip,
            //   fareOptions: [selectedTrip.fareOptions],
            // }, isVietnamese, isRemoveAccents)

            // Hành lý: a công bảo tạm ẩn đi
            // Xong a Công bảo thêm config cho hiện ra
            // if (employeeConfig.value.showAddonForSms) {
            //   if (boardingBaggage?.carryBag) {
            //     sms += `${t('flight.priceReport.carryBag')}: ${boardingBaggage.carryBag}\n`
            //   }
            //   if (boardingBaggage?.checkinBag) {
            //     sms += `${t('flight.priceReport.checkinBag')}: ${boardingBaggage.checkinBag}\n`
            //   }
            // }

            // hanh trinh
            let segmentsText = ''
            selectedTrip.segments.forEach(segment => {
              if (shorten) {
                const nameAirline = getAirlineNameByCode(airline || segment?.airline) || ''
                const departureCode = segment?.departure?.iataCode || segment?.departure?.IATACode || ''
                const arrivalCode = segment?.arrival?.iataCode || segment?.arrival?.IATACode || ''
                const departureTime = convertISODateTime(segment?.departure?.at, segment?.departure?.timeZone || segment?.departure?.timezone).hourMin || ''
                const departureDate = convertISODateTime(segment?.departure?.at, segment?.departure?.timeZone || segment?.departure?.timezone).dayAndMonth || ''
                const arrivalTime = convertISODateTime(segment?.arrival?.at, segment?.arrival?.timeZone || segment?.arrival?.timezone).hourMin || ''
                segmentsText += `${index} ${nameAirline} ${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)} ${departureCode}${arrivalCode} ${departureTime} ${arrivalTime} ${departureDate}\n`
                index += 1
              } else {
                // chuyến bay
                segmentsText += `${t('flight.priceReport.flightNumber')}: ${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}\n`
                // Ngày bay
                const departTime = convertISODateTime(segment.departure.at, segment.departure.timeZone || segment.departure.timezone).dateTime.replace('| ', '')
                const arrivalTime = convertISODateTime(segment.arrival.at, segment.arrival.timeZone || segment.arrival.timezone).dateTime.replace('| ', '')
                segmentsText += `${t('flight.priceReport.departTime')}: ${departTime}\n`
                if (env.showArrivalTimeInPriceReport) {
                  segmentsText += `${t('flight.priceReport.arrivalTime')}: ${arrivalTime}\n`
                }
                // điểm dừng
                const stopPointCode = segment.arrival.IATACode
                if (stopPointCode !== arrivalAirportCode) {
                  const stopPointAirportObj = getAirportByAirportCode(stopPointCode)
                  let stopPointName
                  if (stopPointAirportObj?.group === 'VIỆT NAM' || stopPointAirportObj?.group === 'VIETNAM') {
                    stopPointName = `${t(`vnAirports.${stopPointCode}`)} (${stopPointCode})\n`
                  } else {
                    stopPointName = isVietnamese ? `${stopPointAirportObj?.name}${stopPointAirportObj?.cityName ? `, ${stopPointAirportObj?.cityName}` : ''} (${stopPointCode})` : `${stopPointAirportObj?.name}${stopPointAirportObj?.cityEnName ? `, ${stopPointAirportObj?.cityEnName}` : ''} (${stopPointCode})`
                  }
                  segmentsText += `${t('flight.priceReport.transitAt')}: ${isRemoveAccents ? removeAccents(stopPointName) : stopPointName}\n`
                }
              }
            })
            // nối vào sms text
            if (shorten) {
              sms += `${segmentsText}`
            } else {
              sms += `${t('flight.priceReport.airline')}: ${getAirlineNameByCode(airline)}\n`
              sms += isRemoveAccents ? removeAccents(`${departureAirportName} - ${arrivalAirportName}\n`) : `${departureAirportName} - ${arrivalAirportName}\n`
              sms += `${segmentsText}\n`
            }
          }
        }
        // Giá tiền
        // giá tổng
        // const totalPrice = selectedTrips.value.reduce((a, c) => a + ((c?.fareOptions?.afterMultiple?.total + totalCustomServiceFee(c)?.total) || 0), 0)

        // Giá 1 người lớn (https://discord.com/channels/1054696448110903327/1227481518562218064/1227481522043621459)
        const totalPrice = selectedTrips.value.reduce((a, trip) => a + (resolveFare(trip?.fareOptions, trip) || 0), 0)
        sms += `${shorten ? '\n' : ''}${t('flight.priceReport.total')}: ${formatCurrency(totalPrice)}`
        return sms
      }
      // !SECTION

      // SECTION combination trip list
      if (isCombinationProps.value && combination) {
        let sms1 = ''
        props.titleArr.forEach(titleArrItem => {
          // name of departure airport
          const departureAirportCode = titleArrItem.startPoint
          const departureAirportObj = getAirportByAirportCode(departureAirportCode)
          let departureAirportName
          if (departureAirportObj?.group === 'VIỆT NAM' || departureAirportObj?.group === 'VIETNAM') {
            departureAirportName = `${t(`vnAirports.${departureAirportCode}`)} (${departureAirportCode})`
          } else {
            departureAirportName = isVietnamese
              ? `${departureAirportObj?.cityName}${departureAirportObj?.countryName && departureAirportObj?.countryName !== 'Việt Nam' && departureAirportObj?.countryName !== departureAirportObj?.cityName ? `, ${departureAirportObj?.countryName}` : ''} (${departureAirportCode})`
              : `${departureAirportObj?.cityEnName}${departureAirportObj?.countryEnName && departureAirportObj?.countryEnName !== 'Việt Nam' && departureAirportObj?.countryEnName !== departureAirportObj?.cityEnName ? `, ${departureAirportObj?.countryEnName}` : ''} (${departureAirportCode})`
              // ? `${departureAirportObj?.name}${departureAirportObj?.cityName ? `, ${departureAirportObj?.cityName}` : ''} (${departureAirportCode})`
              // : `${departureAirportObj?.name}${departureAirportObj?.cityEnName ? `, ${departureAirportObj?.cityEnName}` : ''} (${departureAirportCode})`
          }

          // name of arrival airport
          const arrivalAirportCode = titleArrItem.endPoint
          const arrivalAirportObj = getAirportByAirportCode(arrivalAirportCode)
          let arrivalAirportName
          if (arrivalAirportObj?.group === 'VIỆT NAM' || arrivalAirportObj?.group === 'VIETNAM') {
            arrivalAirportName = `${t(`vnAirports.${arrivalAirportCode}`)} (${arrivalAirportCode})`
          } else {
            arrivalAirportName = isVietnamese
              ? `${arrivalAirportObj?.cityName ?? arrivalAirportObj?.name}${arrivalAirportObj?.countryName && arrivalAirportObj?.countryName !== 'Việt Nam' && arrivalAirportObj?.countryName !== arrivalAirportObj?.cityName ? `, ${arrivalAirportObj?.countryName}` : ''} (${arrivalAirportCode})`
              : `${arrivalAirportObj?.cityEnName ?? arrivalAirportObj?.name}${arrivalAirportObj?.countryEnName && arrivalAirportObj?.countryEnName !== 'Việt Nam' && arrivalAirportObj?.countryEnName !== arrivalAirportObj?.cityEnName ? `, ${arrivalAirportObj?.countryEnName}` : ''} (${arrivalAirportCode})`

            // ? `${arrivalAirportObj?.name}${arrivalAirportObj?.cityName ? `, ${arrivalAirportObj?.cityName}` : ''} (${arrivalAirportCode})`
            // : `${arrivalAirportObj?.name}${arrivalAirportObj?.cityEnName ? `, ${arrivalAirportObj?.cityEnName}` : ''} (${arrivalAirportCode})`
          }

          // departure airport - arrival airport
          sms1 += isRemoveAccents ? removeAccents(`${departureAirportName} - ${arrivalAirportName}\n`) : `${departureAirportName} - ${arrivalAirportName}, `

          // departure date
          const departDate = titleArrItem.departDate
          const formattedDepartDate = convertISODateTime(departDate).date
          const departWeekday = isVietnamese ? getLongWeekdayFromIso(departDate, '+07:00', 'vi') : getLongWeekdayFromIso(departDate, '+07:00', 'en')
          sms1 += `${isRemoveAccents ? removeAccents(upperFirst(departWeekday)) : upperFirst(departWeekday)} ${formattedDepartDate}\n`
        })

        let sms3 = ''
        const airlines = []

        // eslint-disable-next-line arrow-body-style
        sms3 = combinationTripList.value.map(trip => {
          return `${trip.airline}  ${formatCurrency(trip.fare)}\n${trip.journeys.join('\n')} `
        }).join('\n\n')

        // note about flight no
        let sms2 = ''

        if (airlines.length) {
          sms2 += '========>*<========\n'
          sms2 += '('
          airlines.forEach((airlineCode, index) => {
            sms2 += `${index === 0 ? '' : ' '}`
            sms2 += `${airlineCode}: ${getAirlineNameByCode(airlineCode)}`
            sms2 += `${index !== airlines.length - 1 ? '; ' : ''}`
          })
          sms2 += ')\n\n'
        }
        // sms2 += '========><========\n\n'

        if (!sms3) sms2 = ''
        const viUnSupport = '\nChưa hỗ trợ báo giá cho giá kết hợp!!!'
        const enUnSupport = '\nPrice report for combined prices are not supported yet!!!'
        const unSupport = isVietnamese ? (isRemoveAccents ? removeAccents(viUnSupport) : viUnSupport) : enUnSupport
        return sms1 + (unSupport || sms2 + sms3)
      }
      // !SECTION

      // SECTION handle trip list
      let sms1 = ''

      // name of departure airport
      const departureAirportCode = props.titleArr[segmentIndex].startPoint
      const departureAirportObj = getAirportByAirportCode(departureAirportCode)
      let departureAirportName
      if (departureAirportObj?.group === 'VIỆT NAM' || departureAirportObj?.group === 'VIETNAM') {
        departureAirportName = `${t(`vnAirports.${departureAirportCode}`)} (${departureAirportCode})`
      } else {
        departureAirportName = isVietnamese
          ? `${departureAirportObj?.cityName}${departureAirportObj?.countryName && departureAirportObj?.countryName !== 'Việt Nam' && departureAirportObj?.countryName !== departureAirportObj?.cityName ? `, ${departureAirportObj?.countryName}` : ''} (${departureAirportCode})`
          : `${departureAirportObj?.cityEnName}${departureAirportObj?.countryEnName && departureAirportObj?.countryEnName !== 'Việt Nam' && departureAirportObj?.countryEnName !== departureAirportObj?.cityEnName ? `, ${departureAirportObj?.countryEnName}` : ''} (${departureAirportCode})`
          // ? `${departureAirportObj?.name}${departureAirportObj?.cityName ? `, ${departureAirportObj?.cityName}` : ''} (${departureAirportCode})`
          // : `${departureAirportObj?.name}${departureAirportObj?.cityEnName ? `, ${departureAirportObj?.cityEnName}` : ''} (${departureAirportCode})`
      }

      // name of arrival airport
      const arrivalAirportCode = props.titleArr[segmentIndex].endPoint
      const arrivalAirportObj = getAirportByAirportCode(arrivalAirportCode)
      let arrivalAirportName
      if (arrivalAirportObj?.group === 'VIỆT NAM' || arrivalAirportObj?.group === 'VIETNAM') {
        arrivalAirportName = `${t(`vnAirports.${arrivalAirportCode}`)} (${arrivalAirportCode})`
      } else {
        arrivalAirportName = isVietnamese
          ? `${arrivalAirportObj?.cityName ?? arrivalAirportObj?.name}${arrivalAirportObj?.countryName && arrivalAirportObj?.countryName !== 'Việt Nam' && arrivalAirportObj?.countryName !== arrivalAirportObj?.cityName ? `, ${arrivalAirportObj?.countryName}` : ''} (${arrivalAirportCode})`
          : `${arrivalAirportObj?.cityEnName ?? arrivalAirportObj?.name}${arrivalAirportObj?.countryEnName && arrivalAirportObj?.countryEnName !== 'Việt Nam' && arrivalAirportObj?.countryEnName !== arrivalAirportObj?.cityEnName ? `, ${arrivalAirportObj?.countryEnName}` : ''} (${arrivalAirportCode})`

        // ? `${arrivalAirportObj?.name}${arrivalAirportObj?.cityName ? `, ${arrivalAirportObj?.cityName}` : ''} (${arrivalAirportCode})`
        // : `${arrivalAirportObj?.name}${arrivalAirportObj?.cityEnName ? `, ${arrivalAirportObj?.cityEnName}` : ''} (${arrivalAirportCode})`
      }

      // departure airport - arrival airport
      sms1 += isRemoveAccents ? removeAccents(`${departureAirportName} - ${arrivalAirportName}\n`) : `${departureAirportName} - ${arrivalAirportName}\n`

      // departure date
      const departDate = props.titleArr[segmentIndex].departDate
      const formattedDepartDate = convertISODateTime(departDate).date
      const departWeekday = isVietnamese ? getLongWeekdayFromIso(departDate, '+07:00', 'vi') : getLongWeekdayFromIso(departDate, '+07:00', 'en')
      sms1 += `${isRemoveAccents ? removeAccents(upperFirst(departWeekday)) : upperFirst(departWeekday)} ${formattedDepartDate}\n`

      let sms3 = ''
      let hasBL = false
      let has0V = false
      const airlines = []

      sms3 = await Promise.all(props.tripsArr[segmentIndex].map(async (trip, tripIndex) => {
        const isRenderTrip = props.isRenderTripsArr[segmentIndex][tripIndex] === undefined
          ? true
          : props.isRenderTripsArr[segmentIndex][tripIndex]

        if (!isRenderTrip) {
          return
        }
        // check hết chỗ (c Hằng)
        if (!trip.fareOptions.length) return

        let skipSkyboss = false
        if (getFilterByFareTypeBusiness.value) {
          if (getFilterByFareTypeSkyboss.value) {
            skipSkyboss = true
          }
          const filterRegex = getFilterByFareTypeSkyboss.value ? BUSINESS_SKYBOSS_FILTER_REGEX : BUSINESS_FILTER_REGEX
          trip.fareOptions = trip?.fareOptions.filter(fareItem => ((['1S', 'VN1A'].includes(trip.source) && fareItem.groupClass.startsWith('B')) || (['QH', 'VJ', '1G'].includes(trip.source) && filterRegex.test(fareItem.fareType))))
        }
        if (getFilterByFareTypeSkyboss.value && !skipSkyboss) {
          trip.fareOptions = trip?.fareOptions.filter(fareItem => ['VJ'].includes(trip.source) && SKYBOSS_FILTER_REGEX.test(fareItem.fareType))
        }

        let airlinesAndFlightNumbers = ''
        trip.segments.forEach((segment, sIndex) => {
          if (sIndex > 0) airlinesAndFlightNumbers += ' | '
          if (!airlines.includes(segment.airline)) airlines.push(segment.airline)
          let airlineAndFlightNumber = `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}`
          if (segment.operating === 'BL') {
            hasBL = true
            airlineAndFlightNumber += ' (BL)'
          }
          if (segment.operating === '0V') {
            has0V = true
            airlineAndFlightNumber += ' (0V)'
          }
          airlinesAndFlightNumbers += airlineAndFlightNumber
        })
        const departTime = convertISODateTime(trip.departure.at, trip.departure.timeZone || trip.departure.timezone).time
        const arrivalTime = convertISODateTime(trip.arrival.at, trip.arrival.timeZone || trip.arrival.timezone).time

        const minFare = getMinFareOptions(trip)
        const minFareAdult = trip.fareOptions.length ? (minFare !== '' && !isNil(minFare) ? formatCurrency(minFare) : minFare) : t('flight.full')

        // TODO: check trip.segments[1] ???
        const getBag = await getBaggageInSearchBooking(trip, isVietnamese, isRemoveAccents)
        let boardingBaggage = getBag?.text
        if (
          boardingBaggage
          && (boardingBaggage.includes('pc ')
          || boardingBaggage.includes('kiện ')
          || boardingBaggage.includes('KIỆN ')
          || boardingBaggage.includes('Không bao gồm')
          || boardingBaggage.includes('Khong bao gom')
          || boardingBaggage.includes('Not included'))
        ) {
          boardingBaggage = boardingBaggage
            .replaceAll('pc', 'PC')
            .replaceAll('kiện', 'PC')
            .replaceAll('KIỆN', 'PC')
            .replaceAll('PC', `${t('ticket.pack')}`)
            .replaceAll('(', '')
            .replaceAll(')', '')
            // .replaceAll('Không bao gồm', 'Không ký gửi')
            .replaceAll('Không bao gồm', '')
            // .replaceAll('Khong bao gom', 'Khong ky gui')
            .replaceAll('Khong bao gom', '')
            // .replaceAll('Not included', 'Not checkin baggage')
            .replaceAll('Not included', '')
        }
        // const boardingBaggageByConfig = employeeConfig.value.showAddonForSms && boardingBaggage ? `(${boardingBaggage})` : ''
        const boardingBaggageByConfig = boardingBaggage ? `(${boardingBaggage})` : ''

        // eslint-disable-next-line consistent-return
        return `${airlinesAndFlightNumbers} ${env.showArrivalTimeInPriceReport ? '-' : ' '} ${departTime}${env.showArrivalTimeInPriceReport ? ` → ${arrivalTime}` : ''}     ${env.showArrivalTimeInPriceReport ? `${t('flight.priceReport.price')}: ` : ''}${minFareAdult} ${boardingBaggageByConfig}\n`
      })).then(res => res.join(''))

      // note about flight no
      let sms2 = ''
      sms2 += '========>*<========\n'

      sms2 += '('
      airlines.forEach((airlineCode, index) => {
        sms2 += `${index === 0 ? '' : ' '}`
        sms2 += `${airlineCode}: ${getAirlineNameByCode(airlineCode)}`
        sms2 += `${index !== airlines.length - 1 ? '; ' : ''}`

        if (airlineCode === 'VN' && hasBL) {
          sms2 += `${index === airlines.length - 1 ? '; ' : ' '}VN-6xxx (BL): ${getAirlineNameByCode('BL')}${index === airlines.length - 1 ? '' : '; '}`
        }
        if (airlineCode === 'VN' && has0V) {
          sms2 += `${index === airlines.length - 1 ? '; ' : ' '}VN-8xxx (0V): ${getAirlineNameByCode('0V')}${index === airlines.length - 1 ? '' : '; '}`
        }
      })
      sms2 += ')\n\n'
      // sms2 += '========><========\n\n'

      if (!sms3) sms2 = ''
      return sms1 + sms2 + sms3
      // !SECTION
    }

    function setQuoteSmsContents() {
      const arr = []
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < segmentsCount.value; index++) {
        const combination = index === segmentsCount.value - 1
        const smsContents = []
        this.getQuoteSmsContent(index, key => this.$t(key, 'vi'), {
          isVietnamese: true, isRemoveAccents: false, shorten: false, combination,
        }).then(res => smsContents.push(res))
        this.getQuoteSmsContent(index, key => this.$t(key, 'vi'), {
          isVietnamese: true, isRemoveAccents: false, shorten: true, combination,
        }).then(res => smsContents.push(res))
        this.getQuoteSmsContent(index, key => removeAccents(this.$t(key, 'vi')), {
          isVietnamese: true, isRemoveAccents: true, shorten: false, combination,
        }).then(res => smsContents.push(res))
        this.getQuoteSmsContent(index, key => this.$t(key, 'en'), {
          isVietnamese: false, isRemoveAccents: false, shorten: false, combination,
        }).then(res => smsContents.push(res))

        arr.push(smsContents)
      }

      smsContentsArr.value = arr
    }

    function onShowModalQuote() {
      this.setQuoteSmsContents()
    }

    watch(
      () => props.segmentTabIndex,
      newIndex => {
        segmentIndex.value = newIndex
      },
    )

    const isDisabledShortenPriceReport = computed(() => !selectedTrips.value.every(item => !!item))

    const textVnWithAccent = computed(() => smsContentsArr.value[segmentIndex.value] ? smsContentsArr.value[segmentIndex.value][0] : '')
    const textVnWithAccentShorten = computed(() => smsContentsArr.value[segmentIndex.value] ? smsContentsArr.value[segmentIndex.value][1] : '')
    const textVnNoAccent = computed(() => smsContentsArr.value[segmentIndex.value] ? smsContentsArr.value[segmentIndex.value][2] : '')
    const textEn = computed(() => smsContentsArr.value[segmentIndex.value] ? smsContentsArr.value[segmentIndex.value][3] : '')

    // export pdf
    const splitAmount = ref(25)
    function exportItinerary(type) {
      this.$bvModal.show('modal-api-loading')
      store.dispatch('app/setTextLoading', 'Bắt đầu in báo giá...')
      // eslint-disable-next-line no-restricted-syntax
      console.time('exportItinerary')
      const searchFlightArray = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`]()
      setTimeout(async () => {
        const IMAGE_SCALE = 1.5
        const margin = 20
        let pdf
        let pdfWidth
        let pdfHeight
        const currentTabIndex = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getTabIndex`]
        await searchFlightArray.reduce((promiseFlight, itemFlight, indexFlight) => promiseFlight
          .then(async () => {
            store.dispatch('app-flight-v2/setTabIndex', indexFlight)
            const exportElement = document.getElementById(`ItineraryTab-${indexFlight}`)
            const itineraryElements = exportElement.getElementsByClassName('itinerary')

            const groups = chunk(itineraryElements, splitAmount.value)

            await groups.reduce((promise, group, index) => promise
              .then(async () => {
                Array.from(itineraryElements).forEach(element => {
                  element.classList.add('d-none')
                })
                group.forEach(element => {
                  element.classList.remove('d-none')
                })

                const header = document.createElement('div')
                header.innerText = `Báo giá ${itemFlight.startPoint}-${itemFlight.endPoint} ${itemFlight.departDate} - Trang ${index + 1}/${groups.length}`
                header.style.textAlign = 'center'
                header.style.padding = '10px'
                header.style.fontSize = '20px'
                header.className = 'fw-700'
                header.style.color = '#000'
                header.style.backgroundColor = '#f5f5f5'
                header.style.borderBottom = '2px solid #ddd'

                exportElement.insertBefore(header, exportElement.firstChild)
                exportElement.style.maxWidth = '1200px'

                store.dispatch('app/setTextLoading', `Tạo trang ${index + 1}/${groups.length}...`)
                await html2canvas(exportElement, {
                  scrollX: 0,
                  scrollY: -window.scrollY,
                  useCORS: true,
                  scale: IMAGE_SCALE,
                })
                  .then(canvas => {
                    if (type === 'img') {
                      const image = canvas.toDataURL('image/png', {
                        allowTaint: false,
                        useCORS: true,
                        quality: 1,
                      })
                      const link = document.createElement('a')
                      link.href = image
                      const fileName = `BAOGIA_${itemFlight.startPoint}-${itemFlight.endPoint}_${itemFlight.departDate}_Part${index + 1}.png`
                      link.download = fileName
                      store.dispatch('app/setTextLoading', `Tải xuống trang ${index + 1}/${groups.length}...`)
                      link.click()
                    }
                    else if (type === 'pdf') {
                      const canvasHeight = canvas.height / IMAGE_SCALE
                      const canvasWidth = canvas.width / IMAGE_SCALE
                      const orientation = canvasWidth > canvasHeight ? 'l' : 'p'
                      pdfWidth = canvasWidth + margin * 2
                      pdfHeight = canvasHeight + margin * 2
                      if (index === 0 && !pdf) {
                        // eslint-disable-next-line new-cap
                        pdf = new jsPDF(orientation, 'px', [pdfWidth, pdfHeight], true)
                      }
                      else if (pdf) {
                        pdf.addPage([pdfWidth, pdfHeight], orientation)
                      }
                      canvas.getContext('2d')
                      const imgData = canvas.toDataURL('image/png', {
                        allowTaint: false,
                        useCORS: true,
                        quality: 1,
                      })
                      pdf.addImage(imgData, 'PNG', margin, margin, canvasWidth, canvasHeight)
                    }
                  })
                  .finally(() => {
                    Array.from(itineraryElements).forEach(element => {
                      element.classList.remove('d-none')
                    })
                    header.remove()
                    exportElement.style.maxWidth = 'unset'
                  })
              }), Promise.resolve())
          }), Promise.resolve())

        if (type === 'pdf') {
          store.dispatch('app/setTextLoading', 'Hoàn thành: Tải xuống tệp PDF ...')
          const FileName = `BAOGIA_${searchFlightArray.map(itemFlight => `${itemFlight.startPoint}-${itemFlight.endPoint}_${itemFlight.departDate}`).join('_')}.pdf`
          await pdf.save(FileName)
        }

        store.dispatch('app-flight-v2/setTabIndex', currentTabIndex)

        this.$bvModal.hide('split-amount-modal')
        this.$bvModal.hide('modal-api-loading')
        store.dispatch('app/setTextLoading', null)
        // eslint-disable-next-line no-restricted-syntax
        console.timeEnd('exportItinerary')
      }, 100)
    }

    const disabledExport = computed(() => isCombinationProps.value && (segmentIndex.value === (segmentsCount.value - 1)))

    return {
      segmentIndex,
      tabIndex,
      smsContentsArr,
      disabledCopy,

      getBaggagesByBookingClass,
      getHandBaggage,

      copySmsContentToClipboard,

      getQuoteSmsContent,
      setQuoteSmsContents,
      onShowModalQuote,
      isDisabledShortenPriceReport,
      textVnWithAccent,
      textVnWithAccentShorten,
      textVnNoAccent,
      textEn,
      isCombinationProps,
      exportItinerary,
      splitAmount,
      disabledExport,
    }
  },
}
</script>

<style lang="scss">
.modal-dialog.modal-reservation-sms {
  width: 640px !important;
  max-width: 640px !important;
}
</style>
